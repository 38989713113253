<template>
  <EntityFilePanel :project="project" />
</template>

<script>
import EntityFilePanel from '@/components/properties/EntityFilePanel'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectFiles',
  components: {
    EntityFilePanel
  },
  computed: {
    ...mapGetters('properties', ['getProjectById']),

    project () {
      return this.getProjectById(this.$route.params.id)
    }
  }
}
</script>
