<template>
  <div>
    <div class="form-group">
      <div class="tabs-container col-sm-12" style="padding-bottom: 15px;">
        <ul class="nav nav-tabs">
          <li class="active">
            <a :href="`#tab-NL-${val.id}-${uniqueKey}`" aria-expanded="true" data-toggle="tab" @click="activeTab = 'NL'">
              NL
            </a>
          </li>
          <li>
            <a :href="`#tab-FR-${val.id}-${uniqueKey}`" aria-expanded="false" data-toggle="tab" @click="activeTab = 'FR'">
              FR
            </a>
          </li>
          <li>
            <a :href="`#tab-EN-${val.id}-${uniqueKey}`" aria-expanded="false" data-toggle="tab" @click="activeTab = 'EN'">
              EN
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div :id="`tab-NL-${val.id}-${uniqueKey}`" :class="['tab-pane', { active: activeTab === 'NL' }]">
            <div class="panel-body">
              <label class="control-label col-md-4">{{ label }}</label>
              <div class="col-md-8">
                <textarea class="form-control" v-model="val.text_nl"></textarea>
              </div>
            </div>
          </div>
          <div :id="`tab-FR-${val.id}-${uniqueKey}`" :class="['tab-pane', { active: activeTab === 'FR' }]">
            <div class="panel-body">
              <label class="control-label col-md-4">{{ label }}</label>
              <div class="col-md-8">
                <textarea class="form-control" v-model="val.text_fr"></textarea>
              </div>
            </div>
          </div>
          <div :id="`tab-EN-${val.id}-${uniqueKey}`" :class="['tab-pane', { active: activeTab === 'EN' }]">
            <div class="panel-body">
              <label class="control-label col-md-4">{{ label }}</label>
              <div class="col-md-8">
                <textarea class="form-control" v-model="val.text_en"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

export default {
  name: 'TranslatableField',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    label: {
      type: String
    }
  },
  data () {
    return {
      activeTab: 'NL'
    }
  },
  computed: {
    uniqueKey () {
      return uniqueId()
    },
    val: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>
